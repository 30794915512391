import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: "app-asset-library-placeholder",
    templateUrl: "asset-library-placeholder.component.html",
    styleUrls: ["asset-library-placeholder.component.scss"],
})

export class AssetLibraryPlaceholderComponent {
    @Input() imageUrl: string;
    @Output() openAssetLibrary: EventEmitter<MouseEvent> = new EventEmitter();
    constructor() {
    }

    onClick($event) {
        this.openAssetLibrary.next($event);
    }
}
