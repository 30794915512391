export const environment = {
    production: true,
    stage: "prod",

    versionPollingInterval: 1000 * 60 * 30, // 30min

    apiUrlPrefix: "https://api.brame-gamification.com",
    keyCloakApiUrlPrefix: "https://api.brame-gamification.com/keycloak",
    campaignDataUrlPrefix: "https://api.brame-gamification.com/campaigns-data", // needs to be parametrized for each evnironment
    analyticsEndpoint: "analytics.brame-gamification.com",
    buildServiceApiUrlPrefix: "https://api.brame-gamification.com/build",
    resourceServiceApiUrlPrefix: "https://api.brame-gamification.com/campaign-resource",

    serverlessApiBaseUrl: "https://serverless-api.brame-gamification.com",
    surveyApiUrlPrefix: "https://serverless-api.brame-gamification.com/survey-game/api",
    memoryApiBaseUrl: "https://serverless-api.brame-gamification.com/memory-game",
    scratchCardApiBaseUrl: "https://serverless-api.brame-gamification.com/scratch-card",
    pennySpinTheWheelApiBaseUrl: "https://serverless-api.brame-gamification.com/penny-stw",
    advancedSpinTheWheelApiBaseUrl: "https://serverless-api.brame-gamification.com/advanced-spin-the-wheel",
    prizeManagementApiBaseUrl: "https://serverless-api.brame-gamification.com/prize-management",
    pennyPathApiBaseUrl: "https://serverless-api.brame-gamification.com/penny-path-v2",
    pennyPenaltyApiBaseUrl:"https://dev-serverless-api.brame-gamification.com/penny-penalty",
    quizApiBaseUrl: "https://serverless-api.brame-gamification.com/quiz",
    timeZonesApiBaseUrl: "https://serverless-api.brame-gamification.com/time-zone",
    eventsApiBaseUrl: "https://serverless-api.brame-gamification.com/events",
    pennySlotMachineApiBaseUrl: "https://serverless-api.brame-gamification.com/penny-slot-machine",

    campaignPreviewBaseUrl: "https://preview.brame-gamification.com",
    campaignDataStorage: "https://brame-campaign-data-storage.s3.amazonaws.com/campaign-data",
    pennyLeadsExportUrl: "",
    memoryGameLeadsExportUrl: "https://ffd63vuwdhzi4nq3mf2mnts4n40lmdve.lambda-url.eu-central-1.on.aws",
    pennySlotMachineLeadsExportUrl: "",
    pennyPathLeadsExportUrl: "",
    advancedSpinTheWheelExportUrl: "https://pf3dwsiunynxqz2imhffinznwy0nzfkh.lambda-url.eu-central-1.on.aws",
    pennyPenaltyLeadsExportUrl: "",

    integrationAppAuthEndpoint: "https://serverless-api.brame-gamification.com/integration-app",

    integrationApp: {
        selfHosted: true,
        authEndpoint: "https://serverless-api.brame-gamification.com/integration-app",
        approvedIntegrations: [
            { key: "hubspot", flowKey: "brame-lead-universal-flow" },
            { key: "mailchimp", flowKey: "brame-lead-universal-flow" },
            { key: "salesforce-pardot", flowKey: "brame-lead-universal-flow" },
        ],
    },
    isDemoEnv: false,
    enableDemo:false,

    cookies: {
        brame_at: "brame_at",
        refresh_brame_at: "refresh_brame_at",
        session: "SESSION",
    },
    contextKeyName: "brame-context",

    // eslint-disable-next-line max-len
    fusionChartsKey: "PtB2dF-7wB4C5C3D5A3G3F3A2B8B7D2B3F6tgoD3B1d1fdzoG4I3I-7jD-17A3D5B3D5D4B3J4C3D7C5C2B4A4G1D2G1rmoE6E1C4gI4B3C8zyhD3D2E3vraA4B2C2zbnD1H4B3A5B3F4F3H3G4A19A32aikC6D3RH4moA-31A7C8A3wfA2IC1C5rA-16mD2D5F1A2B2B7A3D6A3C2E4E2D2C3u==",
    // Mixpanel
    mixpanelKey: "d8bfafe949d6dd7b4e5203edf825cb3b",
    mixpanelProdEnv: true,
    backendAnalyticsEvents: {
        SUPPORTED_GAMES: ["scratch-card", "survey", "memory-game", "quiz"],
        SUPPORTED_EVENTS: ["game_started", "game_finished", "lead_created"],
    },

    // Feature flags
    feautreFlagAppIdentifier: "3t9lzc2",
    feautreFlagProfileIdentifier: "dwom2ce",
    feautreFlagEnvIdentifier: "bg1fuhh",
    featureFlagClientConfig: {
        region: "eu-central-1",
        credentials: {
            accessKeyId: "AKIATOHVODOV3D2XD7OV",
            secretAccessKey: "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",
        },
    },
    leadsExports: {
        endpoint: 'https://csokgzsbjhn5at53fl7lh7ud5i0qdsww.lambda-url.eu-central-1.on.aws/',
    },
    configCat: {
        sdkKey: 'configcat-sdk-1/pKDcCBJx0k2HOFPyJbWnQg/GBHwCwqJIEC1dak_jgnHXg',
    },
};
