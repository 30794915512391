<div class="wrapper {{ customClass ? customClass : '' }}">
  <label class="label">{{ labelText }}</label>
  <textarea
    cols="30"
    [rows]="rows"
    class="form-control"
    [value]="val"
    (input)="onChange($event.target.value)"
    (blur)="onTouch()"
    [placeholder]="placeholder"
    [class.invalid]="invalid"
    [attr.autofocus]="autoFocus ? true : null"
    [maxLength]="maxChars"
  ></textarea>

  <div class="error {{ customErrorClass ? customErrorClass : '' }}">
    <ng-content></ng-content>
  </div>
</div>