import { Injectable } from "@angular/core";
import {
    AppConfigDataClient,
    BadRequestException,
    GetLatestConfigurationCommand,
    StartConfigurationSessionCommand,
} from "@aws-sdk/client-appconfigdata";
import { FeatureFlags, FeatureFlagsAppConfig } from "@models/feature-flags/FeatureFlags";
import { FeatureFlagConfig } from "@models/feature-flags/FeatureFlagConfig";
import { environment } from "../../../../environments/environment";
import { FeatureFlagsProvider } from "./feature-flags.provider";

@Injectable({ providedIn: "root" })
export class AppConfigFeatureFlagsProvider implements FeatureFlagsProvider {
    features: FeatureFlags = {};
    client: AppConfigDataClient;
    appConfig: FeatureFlagsAppConfig;
    sessionToken: string | undefined;

    constructor() {
        this.client = new AppConfigDataClient(
            environment.featureFlagClientConfig
        );
        this.appConfig = {
            ApplicationIdentifier: environment.feautreFlagAppIdentifier,
            ConfigurationProfileIdentifier: environment.feautreFlagProfileIdentifier,
            EnvironmentIdentifier: environment.feautreFlagEnvIdentifier,
        };
    }

    async getSessionToken() {
        const getSession = new StartConfigurationSessionCommand(this.appConfig);
        const sessionToken = await this.client.send(getSession);
        return sessionToken.InitialConfigurationToken;
    }

    async getFeatureFlags() {
        if (!this.sessionToken) {
            this.sessionToken = await this.getSessionToken();
        }

        try {
            const command = new GetLatestConfigurationCommand({
                ConfigurationToken: this.sessionToken,
            });
            const response = await this.client.send(command);
            if (response.Configuration) {
                this.features = this.parseConfiguration(response.Configuration);
            }

        } catch (error) {
            if (error instanceof BadRequestException) {
                this.sessionToken = await this.getSessionToken();
                return this.getFeatureFlags();
            }

            throw error;
        }
    }

    isFeatureFlagEnabled(feature: FeatureFlagConfig) {
        return !!(this.features[feature] && this.features[feature].enabled);
    }

    private parseConfiguration(config: any) {
        let str = "";
        for (let i = 0; i < config.length; i++) {
            str += String.fromCharCode(config[i]);
        }
        return Object.assign({}, JSON.parse(str));
    }
}
