import { NgModule } from "@angular/core";

import { NgxImageCompressService } from "ngx-image-compress";
import { getClient } from 'configcat-js';

import { FeatureFlagsService } from "@services/feature-flags/feature-flags.service";
import { ContextService } from "./context.service";
import { MemoryService } from "./game-data/memory.service";
import { ConstantsService } from "./constants.service";
import { CONFIGCAT_CLIENT } from "../constants";
import { environment } from "src/environments/environment";

@NgModule({
    providers: [
        ContextService,
        MemoryService,
        ConstantsService,
        NgxImageCompressService,
        {
            provide: CONFIGCAT_CLIENT,
            useFactory: () => {
                return getClient(environment.configCat.sdkKey);
            },
        },
        FeatureFlagsService,
    ],
})
export class ServicesModule {}
