import {CampaignLanguage} from "@models/CampaignLanguage";
import {Asset} from "@builder-models/Asset";
import {LanguageSettings} from "../../../shared/components/language-chooser/models/LanguageSettings";
import {Outcome} from "./Outcome";

export class MemoryGameData {
    coverImageUrl: string;
    tiles: Tile[];
    outcomes: MemoryOutcome[];
    multiRegistration?: boolean;
    playsPerDay?: number;
}

export class Tile {
    id?: string;
    imageUrl: string;
}

export class MemoryOutcome {
    id?: string;
    upTimeThreshold = 0;
    downTimeThreshold = 0;
    outcomes: Outcome[] = new Array<Outcome>(new Outcome("", "", ""));
    orderNumber?: number;
}

export class InitialMemoryGameData {
    coverImageUrl: string;
    tiles: string[];
    outcomes: MemoryOutcome[];
    multiRegistration?: boolean;
    playsPerDay?: number;
}

export class MemoryGameDataFactory {
    static getInitial(data: Asset[], languages: CampaignLanguage[]): InitialMemoryGameData {
        return {
            coverImageUrl: data[0].url,
            tiles: [data[1].url, data[2].url, data[3].url, data[4].url],
            outcomes: [{
                downTimeThreshold: 0,
                upTimeThreshold: -1,
                outcomes: languages.map((lang: LanguageSettings) => ({
                    title: String(lang.shortName) + "-title",
                    text: String(lang.shortName) + "-text",
                    image: data[5].url,
                    languageId: lang.id,
                    languageCode: lang.shortName,
                })),
            }],
        };
    }
}
