import {Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";


@Component({
    selector: "app-select-input",
    templateUrl: "select-input.component.html",
    styleUrls: ["select-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectInputComponent),
            multi: true,
        },
    ],
})
export class SelectInputComponent implements ControlValueAccessor {

    @Input() options: any[] = []; // TODO enable input options to be Array of objects and Array of primitive values
    @Input() mini = false;
    @Input() showField: string; // which field to show  if it is array of objects null for array of primitives
    @Input() translate: boolean; //
    @Input() valueField: string; // which field to select from option object
    @Input() labelText: string;
    @Input() disabled = false;
    @Input() maxWordLength = 50;
    @Input() customClass = "";
    @Input() showFontUpload = false;
    @Input() placeholder = "";
    @Output() change = new EventEmitter();

    @HostListener("document:click", ["$event"]) onClick() {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.dropdownOpened = false;
        }
    }

    selectedItem = null;
    dropdownOpened = false;

    constructor(
        private eRef: ElementRef,
    ) { }

    ngOnChanges() {
        // options might change
        this.selectedItem = this.getSelectedItem(this.val);
    }

    onChange: any = () => { };
    onTouch: any = () => { };
    val = ""; // this is the updated value that the class accesses

    set value(val: string) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
        this.val = val;
        this.selectedItem = this.getSelectedItem(val);
        this.onChange(val);
        this.onTouch(val);
    }

    toggleDropdownMenu = () => this.dropdownOpened = !this.dropdownOpened;

    onItemSelected(item: { [x: string]: any }) {
        if (!item) {
            return;
        }
        this.selectedItem = item;
        const value = this.valueField ? item[this.valueField] : item;
        this.val = value;
        this.onChange(value);
        this.change.emit(item);
        this.toggleDropdownMenu();
    }

    private getSelectedItem(val) {
        return this.options.find((el) =>
            this.valueField
                ? el[this.valueField] === val
                : (el.id)
                    ? el.id === (val ? val.id : val)
                    : el === val);
    }

    // this method sets the value programmatically
    writeValue(value: any) {
        this.value = value;
    }
    // upon UI element value changes, this method gets triggered
    registerOnChange(fn: any) {
        this.onChange = fn;
    }
    // upon touching the element, this method gets triggered
    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }
}
