import { Inject, Injectable } from "@angular/core";

import { IConfigCatClient } from "configcat-js";

import { FeatureFlagConfig } from "@models/feature-flags/FeatureFlagConfig";

import { FeatureFlagsProvider } from "./feature-flags.provider";
import { CONFIGCAT_CLIENT } from "../../constants";

@Injectable({ providedIn: "root" })
export class ConfigCatFeatureFlagsProvider implements FeatureFlagsProvider {
    constructor(@Inject(CONFIGCAT_CLIENT) private readonly configCatClient: IConfigCatClient) {}

    isFeatureFlagEnabled(feature: FeatureFlagConfig): boolean {
        return this.configCatClient.snapshot().getValue(feature, false);
    }
}