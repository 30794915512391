export enum FeatureFlagConfig {
    WIN_DISTRIBUTION = "win_distribution",
    UNIFIED_ANALYTICS = "unified_analytics",
    INTERNAL_ANALYTICS = "b20_10669_internal_analytics",
    ACCESS_CODES_ANALYTICS = "b20_11396_access_codes_analytics",
    LEADS_VISIBILITY = "b20_12146_leads_visibility",
    ASTW_SWITCH = "b20_10608_disable_register_for_astw",
    MAX_LIMIT_SWITCH = "b20_12864_configurable_win_limit",
    USE_V2_EXPORTS_SERVICE_FOR_SPIN_THE_WHEEL = "e20_120_use_v2_exports_service_for_spin_the_wheel",
    USE_V2_EXPORTS_SERVICE_FOR_SCRATCH_CARD = "e20_120_use_v2_exports_service_for_scratch_card",
    USE_V2_EXPORTS_SERVICE_FOR_PERSONALITY_TEST = "e20_120_use_v2_exports_service_for_personality",
    USE_V2_EXPORTS_SERVICE_FOR_GUESS_THE_PICTURE = "e20_120_use_v2_exports_service_for_gtp",
    USE_V2_EXPORTS_SERVICE_FOR_CALENDAR = "e20_120_use_v2_exports_service_for_calendar",
    USE_V2_EXPORTS_SERVICE_FOR_MEMORY = "e20_120_use_v2_exports_service_for_memory",
    USE_V2_EXPORTS_SERVICE_FOR_QUIZ = "e20_120_use_v2_exports_service_for_quiz",
    USE_V2_EXPORTS_SERVICE_FOR_SURVEY = "e20_120_use_v2_exports_service_for_survey",
}

// NOTE:
// 1. for keys in this map we use VALUES of feature flags
// 2. it is required to be present in FeatureFlagsConfig
// 3. only if value is present in this map, we will use ConfigCat feature flag provider
export const ConfigCatFeatureFlags = {
    "e20_120_use_v2_exports_service_for_spin_the_wheel": "e20_120_use_v2_exports_service_for_spin_the_wheel",
    "e20_120_use_v2_exports_service_for_scratch_card": "e20_120_use_v2_exports_service_for_scratch_card",
    "e20_120_use_v2_exports_service_for_personality": "e20_120_use_v2_exports_service_for_personality",
    "e20_120_use_v2_exports_service_for_gtp": "e20_120_use_v2_exports_service_for_gtp",
    "e20_120_use_v2_exports_service_for_calendar": "e20_120_use_v2_exports_service_for_calendar",
    "e20_120_use_v2_exports_service_for_memory": "e20_120_use_v2_exports_service_for_memory",
    "e20_120_use_v2_exports_service_for_quiz": "e20_120_use_v2_exports_service_for_quiz",
    "e20_120_use_v2_exports_service_for_survey": "e20_120_use_v2_exports_service_for_survey",
};