import {Injectable} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";

@Injectable({
    providedIn: "root",
})

export class ScriptsService {
    constructor(
        private router: Router,
    ) {
    }

    loadScript(src: string, attributes: Array<{name: string; value: string}>) {
        const script = document.createElement("script");
        script.src = src;
        attributes.forEach((attribute) => {
            script.setAttribute(attribute.name, attribute.value);
        });
        document.getElementsByTagName("body")[0].appendChild(script);
        script.addEventListener("load", ()=>{
            window.document.dispatchEvent(new Event("DOMContentLoaded", {
                bubbles: true,
                cancelable: true,
            }));
        });
    }

    loadServiceWidget() {
        const urls = ["/login", "/forgot-password/email", "/others/404"];
        const src = "https://jsd-widget.atlassian.com/assets/embed.js";
        const attributes = [
            {name: "data-jsd-embedded", value: ""},
            {name: "data-key", value: "b23c98c5-51b8-4024-9014-92720332de11"},
            {name: "data-base-url", value: "https://jsd-widget.atlassian.com"},
        ];

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const isScriptInserted = !!document.querySelector("[data-jsd-embedded]");
                if (!urls.includes(event.url)) {
                    if (!isScriptInserted) {
                        this.loadScript(src, attributes);
                    }
                } else {
                    this.removeServiceWidget();
                }
            }
        });
    }

    removeServiceWidget() {
        const script = document.querySelector("[data-jsd-embedded]");
        const iframe = document.getElementById("jsd-widget");
        if (script) {
            script.remove();
            iframe.remove();
        }
    }
}
