import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ContextService } from "src/app/core/services/context.service";
import { Me } from "@models/Me";
import { AuthService } from "src/app/core/auth/auth.service";
import { UserRoles } from "@models/UserRoles";
import { LocalStorageKeys } from "@models/LocalStorageKeys";
import {
    CAMPAIGNS_PAGES_ACCESS,
    PRIZE_MANAGEMENT_PAGES_ACCESS,
} from "src/app/core/constants";
import { MixpanelService } from "@services/mixpanel/mixpanel.service";
import { InternalAnalyticsService } from "@services/internal-analytics/internal-analytics.service";
import { mixpanelEvents } from "@services/mixpanel/mixpanelEvents";
import { BipaAccs } from "src/app/features/analytics/models/BipaAccs";
import { LocalStorageService } from "src/app/core/util-services/local-storage.service";
import { PrizeManagementAccounts } from "@models/prize-management/PrizeManagementAccounts";
import { FeatureFlagsService } from "@services/feature-flags/feature-flags.service";
import { FeatureFlagConfig } from "@models/feature-flags/FeatureFlagConfig";
import { environment } from "../../../../environments/environment";

@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
    me: Me;
    navbarCollapsed = true;
    canManageUsers = false;
    bipaAccess = BipaAccs.getBipaAccountsIds();
    prizeManagementAccounts =
    PrizeManagementAccounts.getPrizeManagementAccounts();

    constructor(
        private router: Router,
        private context: ContextService,
        private authService: AuthService,
        private localStorage: LocalStorageService,
        private mixpanel: MixpanelService,
        private analytics: InternalAnalyticsService,
        private featureFlagsService: FeatureFlagsService,
    ) {}

    ngOnInit() {
        this.me = this.context.getMe();
        this.setCanManageUsers();
    }

    logout() {
        const name = `${String(this.me.firstName)} ${String(this.me.lastName)}`;
        this.authService.logout();
        this.mixpanel.triggerEvent(
            this.me.email,
            name,
            mixpanelEvents[103].name
        );
        if (this.featureFlagsService.isFeatureFlagEnabled(FeatureFlagConfig.INTERNAL_ANALYTICS)) {
            this.analytics.triggerBrameEvent(
                this.me.email,
                name,
                mixpanelEvents[103].name
            ).subscribe();
        }
        void this.router.navigateByUrl("/login");
    }

    isSales() {
        const userRole = this.localStorage.getItem("userRole");
        return userRole.indexOf(UserRoles.SALES_MANAGER) !== -1;
    }

    isAdmin() {
        const userRole = this.localStorage.getItem("userRole");
        return userRole.indexOf(UserRoles.SUPER_ADMIN) !== -1;
    }

    isSuccessManager() {
        return this.roles().includes(UserRoles.SUCCESS_MANAGER);
    }

    shouldShowCampaignsPage() {
        return this.roles().some(
            (role) => CAMPAIGNS_PAGES_ACCESS.indexOf(role) !== -1
        );
    }

    shouldShowPrizeManagementPage() {
        const context = this.localStorage.getItem(environment.contextKeyName);
        const companyViewId = this.localStorage.getItem(
            LocalStorageKeys.COMPANY_VIEW_ID
        );
        if (environment.stage === "penny") {
            return this.hasRoleForPrizeManagement();
        }

        if (
            this.bipaAccess.some(
                (acc: { id: any }) => acc.id === context.company.id
            )
        ) {
            return this.hasRoleForPrizeManagement();
        }

        if (
            this.prizeManagementAccounts.indexOf(context.company.id) !== -1 ||
            this.prizeManagementAccounts.indexOf(companyViewId) !== -1
        ) {
            return this.hasRoleForPrizeManagement();
        }

        return false;
    }

    resetCompanyIdAndRedirect(redirectTo = "/admin-dashboard") {
        const context = this.localStorage.getItem(environment.contextKeyName);
        this.localStorage.setItem(
            LocalStorageKeys.COMPANY_VIEW_ID,
            context.company.id
        );
        void this.router.navigateByUrl(redirectTo);
    }

    forwardToCampaigns(redirectTo = "/campaigns") {
        const company = this.me?.company;
        if (company?.tags?.includes("builder2access")) {
            redirectTo = "/advanced-builder";
        }
        window.location.href = redirectTo;
    }

    isLinkActive(link: string) {
        return link === this.router.url;
    }

    private setCanManageUsers() {
        const ALLOWED_ACCESS_ROLES = [
            UserRoles.USER_ADMIN,
            UserRoles.SUPER_USER_ADMIN,
        ];
        const roles = this.localStorage.getItem("userRole") as UserRoles[];
        this.canManageUsers = roles.some((role) =>
            ALLOWED_ACCESS_ROLES.includes(role)
        );
    }

    private roles(): UserRoles[] {
        return this.localStorage.getItem(
            LocalStorageKeys.USER_ROLE
        ) as UserRoles[];
    }

    private hasRoleForPrizeManagement() {
        return this.roles().some(
            (role) => PRIZE_MANAGEMENT_PAGES_ACCESS.indexOf(role) !== -1
        );
    }
}
